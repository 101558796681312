import React from "react";
import styles from "./NameBoard.module.css";

export default function NameBoard() {
  return (
    <div className={styles.nameBoard}>
      Томская Региональная Общественная Организация <br></br>"Общество Доступная Среда"
    </div>
  );
}
