import React from "react";
import style from "./Program.module.css";

export default function Program() {
  return (
    <div className={style.program_container}>
      <br></br>
      <h2>
        Программа деятельности ТРОО "Общество доступная среда" на 2024 год.
      </h2>
      <table >
        <thead>
          <tr>
            <th>№п/п</th>
            <th>Наименование мероприятия</th>
            <th>Дата проведения </th>
            <th>Место проведения </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>Занятия литературного мини-театра</td>
            <td>Один раз в неделю по четвергам</td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>2.</td>
            <td>
              Посещение храма во имя Святителя Николая Чудотворца и музея
              "Дорожный павильон Цесаревича Николая»
            </td>
            <td>19 января </td>
            <td>с. Семилужки Томский р-н</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>
              Занятия по подготовке экскурсий с «Общественными экскурсоводами»
            </td>
            <td>Один раз в неделю с января по апрель по четвергам</td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>4.</td>
            <td>
              Экскурсия "И.С.Черных - Герой Советского Союза - "Огненный лётчик"
            </td>
            <td>29 февраля</td>
            <td>г. Томск</td>
          </tr>
          <tr>
            <td>5.</td>
            <td>Выставка "Семейные реликвии"</td>
            <td>02 марта - 14 марта </td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>6.</td>
            <td>
              Праздничный концерт художественной самодеятельности и конкурс
              супружеских пар "Обручальное кольцо"
            </td>
            <td>2 марта </td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>7.</td>
            <td>
              Масленичные развлечения с участниками литературного мини-театра
            </td>
            <td>14 марта </td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>8.</td>
            <td>Занятия вокальной группы</td>
            <td>С 13 марта -30 мая один раз в неделю по средам </td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>9.</td>
            <td>Занятия обьеденения "Рукоделие" (вязание без крючка и спиц)</td>
            <td>2 раза в неделю с марта по декабрь </td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>10.</td>
            <td>
              Цикл экскурсий: "Площадь Новособорная", "ВУЗы г.Томска", "Проспект
              Ленина - дома архитектора Лыгина", "Есть такой район Уржатка"
            </td>
            <td>Март - апрель </td>
            <td>г. Томск</td>
          </tr>
          <tr>
            <td>11.</td>
            <td>Экскурсия членов ВОС из с. Кривошеино по г.Томску</td>
            <td>29 марта </td>
            <td>г. Томск</td>
          </tr>
          <tr>
            <td>12.</td>
            <td>
              Тематическая программа "Томские писатели и русские писатели в
              Томске в конце 19го начала 20го веков". Представление
              литературного мини-театра, мини-спектаклей по произведениям
              А.П.Чехова, В.Я.Шишкова, Н.А.Клюева, К.М.Станюковича
            </td>
            <td>29 марта </td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>13.</td>
            <td>Паломническая поездка в г.Новокузнецк</td>
            <td>07 - 11 апреля </td>
            <td>г.Новокузнецк, Кемеровская обл.</td>
          </tr>
          <tr>
            <td>14.</td>
            <td>Экскурсия в музей Георгия Маркова </td>
            <td>Апрель </td>
            <td>с. НовоКусково Асиновский район</td>
          </tr>
          <tr>
            <td>15.</td>
            <td>Мастер-класс "Одеваться красиво и удобно" </td>
            <td>Май </td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>16.</td>
            <td>Мастер-класс "Средства по уходу за кожей лица и рук" </td>
            <td>Май </td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>17.</td>
            <td>Праздник семьи в МО ВОС в с.Кривошеино и г.Колпашево </td>
            <td>08 - 09 июня</td>
            <td>с.Кривошеино и г.Колпашево Томская обл.</td>
          </tr>
          <tr>
            <td>18.</td>
            <td>
              Праздник Св.Троицы и посещение музея под открытым небом «Казачий
              хутор»
            </td>
            <td>23 июня</td>
            <td>с. Семилужки Томский р-н</td>
          </tr>
          <tr>
            <td>19.</td>
            <td>
              Туристическая поездка в с.Подгорное в музей села, в Музей
              «Кулайской культуры»
            </td>
            <td>Июль</td>
            <td>
              Проживание в палатках, на берегу реки Чаи, с.Подгорное Чаинский
              р-н Томская обл.
            </td>
          </tr>
          <tr>
            <td>20.</td>
            <td>
              Туристическая поездка в Кривошеинский район в д.Исламбуль с целью
              знакомства с культурой и бытом местным населением татарами
            </td>
            <td>Август</td>
            <td>
              Проживание в палатках на озере Таптан, д.Исламбуль Кривошеинский
              р-н Томская обл.
            </td>
          </tr>
          <tr>
            <td>21.</td>
            <td>
              Акция "Дары осени в подарок" от садоводов огородников членам
              организации, проживающим без поддержки родственников
            </td>
            <td>Сентябрь</td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>22.</td>
            <td>
              Тематическая программа "Старость меня дома не застанет" ко Дню
              Старшего Поколения
            </td>
            <td>28 сентября</td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>23.</td>
            <td>
              Тематическая программа "Поэтические и музыкальные кружева о
              Томске". Стихи и песни о Томске
            </td>
            <td>12 октября</td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>24.</td>
            <td>Тематическая программа "Женщина-мать о тебе стихи и песни"</td>
            <td>Конец ноября</td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>25.</td>
            <td>
              Концерт духовного песнопения в Отделе библиотечного обслуживания
            </td>
            <td>9 декабря</td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
          <tr>
            <td>26.</td>
            <td>
              Костюмированный Новогодний конкурс чтецов и певцов на тему:
              "Русская зима", "Новый год"
            </td>
            <td>Конец декабря</td>
            <td>
              Отдел библиотечного обслуживания людей с ограничениями
              жизнедеятельности ТОУНБ им. А.С.Пушкина
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
