import React from "react";
import style from "./About.module.css";

export default function About() {
  return (
    <div className={style.about_container}>
      <div style={{ textAlign: "center"}}>
        <br></br>
        <h1>Информация об ТРОО "Общество Доступная Среда"</h1>
        <br />
        <p style={{ textAlign: "justify", margin: "20px", textIndent: "50px" }}>
          Целями Организации являются оказание членами общества, имеющими
          соответствующие предоставляемым услугам образование и профессию помощи
          в социокультурной реабилитации, адаптации и интеграции инвалидов всех
          категорий, вовлечение их и членов их семей в сферу общественной
          деятельности, самодеятельного творчества, в
          физкультурно-оздоровительный процесс.
        </p>
        <p style={{ textAlign: "justify", margin: "20px", textIndent: "50px" }}>
          Предметом деятельности Организации является совокупность видов
          деятельности, посредством которых достигаются общественно-полезные
          цели, предусмотренные уставом, а именно: организация социального
          туризма; организация социокультурных реабилитационных мероприятий,
          творческих конкурсов, курсов по владению компьютеров, кружков по
          совершенствованию знаний и профессионально - трудовых навыков;
          организация службы сопровождения, транспортного обслуживания, решения
          жилищно-бытовых проблем; оказание психологической и педагогической
          помощи инвалидам и членам их семей.
        </p>
        <p style={{ textAlign: "justify", margin: "20px", textIndent: "50px" }}>
          Председателем ТРОО "Общество Доступная Среда" является Игнатова Юлия
          Анатольевна. Тел. +7 913 801 72 53.
        </p>
        <p style={{ textAlign: "justify", margin: "20px", textIndent: "50px" }}>
          Координатор Досугового центра поддержки слепоглухих г. Томска и
          Томской области "Сильные Духом" - Михайлова Валентина Ивановна. Тел.
          +7 962 779 72 44.
        </p>
      </div>
    </div>
  );
}
