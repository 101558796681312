import React from "react";
import style from "./Projects.module.css";
import Symbol from "../../header/symbol/Symbol"

export default function Projects() {
  return (
    <div className={style.projects_container}>
      <Symbol/>
      <h1 className={style.project_title}>
        Проект «Помним, познаём, храним и передаём другим»
      </h1>
      <p className={style.project_text}>
        ТРОО «Общество доступная среда» стала победителем в Конкурсе «Бережливое
        сознание» с проектом «Помним, познаём, храним и передаём другим»!
        Конкурс проводился 6 сентября – 5 октября 2023 года Фондом гуманитарных
        и просветительских инициатив «Соработничество» при поддержке
        Госкорпорации «Росатом» среди организаций, осуществляющих деятельность в
        регионах, входящих в программу «Эффективный регион» и реализующих
        проекты по созданию условий для формирования у граждан бережливого
        сознания. Срок реализации проекта с 01 ноября 2023г. по 30 апреля 2024г.
      </p>

      <h2 className={style.list_title}>
        Список победителей конкурса на предоставление грантов «Бережливое
        сознание»
      </h2>
      <table>
        <thead>
          <tr>
            <th>№п/п</th>
            <th>Номер заявки</th>
            <th>Полное имя организации</th>
            <th>Наименование проекта</th>
            <th>Регион</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>23-6-БС- 000036</td>
            <td>
              АВТОНОМНАЯ НЕКОММЕРЧЕСКАЯ ОРГАНИЗАЦИЯ &quot;ИНКЛЮЗИВНЫЙ ЦЕНТР
              РЕАЛИЗАЦИИ СОЦИАЛЬНЫХ ПРОГРАММ ПОДДЕРЖКИ ДЕТЕЙ, ПОДРОСТКОВ И
              МОЛОДЫХ ЛЮДЕЙ С ОВЗ И ИНВАЛИДНОСТЬЮ &quot;ДЫШИМ ВМЕСТЕ&quot;
            </td>
            <td>Курский изразец в контексте современности</td>
            <td>Курская обл, г Курск</td>
          </tr>
          <tr>
            <td>2</td>
            <td>23-6-БС- 000279</td>
            <td>
              ФЕДЕРАЛЬНОЕ ГОСУДАРСТВЕННОЕ АВТОНОМНОЕ ОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ
              ВЫСШЕГО ОБРАЗОВАНИЯ &quot;НАЦИОНАЛЬНЫЙ ИССЛЕДОВАТЕЛЬСКИЙ
              НИЖЕГОРОДСКИЙ ГОСУДАРСТВЕННЫЙ УНИВЕРСИТЕТ ИМ. Н.И.
              ЛОБАЧЕВСКОГО&quot;
            </td>
            <td>
              Всероссийский культурно- просветительский проект для детей и
              молодежи «История, культурные традиции и наследие: взгляд Молодых»
            </td>
            <td>Нижегородская обл, г Арзамас</td>
          </tr>
          <tr>
            <td className={style.highLight}>3</td>
            <td className={style.highLight}>23-6-БС- 000024</td>
            <td className={style.highLight}>
              ТОМСКАЯ РЕГИОНАЛЬНАЯ ОБЩЕСТВЕННАЯ ОРГАНИЗАЦИЯ ПО ОКАЗАНИЮ ПОМОЩИ В
              СОЦИОКУЛЬТУРНОЙ РЕАБИЛИТАЦИИ, АДАПТАЦИИ И ИНТЕГРАЦИИ ИНВАЛИДОВ
              ВСЕХ КАТЕГОРИЙ &quot;ОБЩЕСТВО ДОСТУПНАЯ СРЕДА&quot;
            </td>
            <td className={style.highLight}>
              Помним, познаём, храним и передаём другим
            </td>
            <td className={style.highLight}>Томская обл, г Томск</td>
          </tr>
          <tr>
            <td>4</td>
            <td>23-6-БС- 000486</td>
            <td>
              МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ КУЛЬТУРЫ &quot;ЦЕНТРАЛЬНАЯ
              РАЙОННАЯ БИБЛИОТЕКА&quot; МУНИЦИПАЛЬНОГО РАЙОНА &quot;ГОРОД
              КРАСНОКАМЕНСК И КРАСНОКАМЕНСКИЙ РАЙОН&quot; ЗАБАЙКАЛЬСКОГО КРАЯ
            </td>
            <td>Детская экологическая гостиная &quot;Тополята&quot;</td>
            <td>Забайкальский край, г. Краснокаменск</td>
          </tr>
          <tr>
            <td>5</td>
            <td>23-6-БС- 000431</td>
            <td>
              АВТОНОМНАЯ НЕКОММЕРЧЕСКАЯ ОРГАНИЗАЦИЯ АГЕНТСТВО РАЗВИТИЯ ДЕТСКОЙ И
              МОЛОДЕЖНОЙ ПРОЕКТНОЙ ДЕЯТЕЛЬНОСТИ И РЕАЛИЗАЦИИ ИНИЦИАТИВ
              &quot;ДИАДА&quot;
            </td>
            <td>Кампус Школьных Инклюзивных Практик (ШИП)</td>
            <td>Нижегородская обл, г Нижний Новгород</td>
          </tr>
          <tr>
            <td>6</td>
            <td>23-6-БС- 000452</td>
            <td>
              АВТОНОМНАЯ НЕКОММЕРЧЕСКАЯ ОРГАНИЗАЦИЯ &quot;ЦЕНТР ИННОВАЦИЙ,
              ФИНАНСОВОГО РАЗВИТИЯ И АКСЕЛЕРАЦИИ&quot;
            </td>
            <td>
              Региональный эколого- патриотический форум &quot;Zа дело!&quot;
            </td>
            <td>Кемеровская область, г Кемерово</td>
          </tr>
          <tr>
            <td>7</td>
            <td>23-6-БС- 000291</td>
            <td>
              АВТОНОМНАЯ НЕКОММЕРЧЕСКАЯ ОРГАНИЗАЦИЯ ПО ОКАЗАНИЮ УСЛУГ В СФЕРЕ
              КУЛЬТУРЫ ЭТНОКОМПЛЕКС &quot;РЕМЕСЛЕННОЕ ПОДВОРЬЕ&quot;
            </td>
            <td>
              Интерактивная экспозиция «Традиционная народная одежда Ивановской
              области»
            </td>
            <td>Ивановская обл, деревня Куликово</td>
          </tr>
          <tr>
            <td>8</td>
            <td>23-6-БС- 000563</td>
            <td>
              БЛАГОТВОРИТЕЛЬНЫЙ ФОНД ПОДДЕРЖКИ МОЛОДОГО ПОКОЛЕНИЯ И СОЦИАЛЬНЫХ
              ИНИЦИАТИВ &quot;БЛАГОДАРЕНИЕ&quot;
            </td>
            <td>&quot;Осознанное потребление- выбор поколения&quot;</td>
            <td>Респ Татарстан, г Казань</td>
          </tr>
          <tr>
            <td>9</td>
            <td>23-6-БС- 000377</td>
            <td>
              АВТОНОМНАЯ НЕКОММЕРЧЕСКАЯ ОРГАНИЗАЦИЯ ОРГАНИЗАЦИИ ДОСУГА
              &quot;ЛАБОРАТОРИЯ ИГР &quot;В-АГОН&quot;
            </td>
            <td>Культурологическая игра &quot;Этноблеф&quot;</td>
            <td>Новосибирская обл, г Новосибирск</td>
          </tr>
          <tr>
            <td>10</td>
            <td>23-6-БС- 000114</td>
            <td>
              МУНИЦИПАЛЬНОЕ УЧРЕЖДЕНИЕ КУЛЬТУРЫ &quot;ЦЕНТРАЛЬНАЯ РАЙОННАЯ
              БИБЛИОТЕКА БЕЛГОРОДСКОГО РАЙОНА&quot;
            </td>
            <td>Мобильное краеведение</td>
            <td>Белгородская область, п. Майский</td>
          </tr>
          <tr>
            <td>11</td>
            <td>23-6-БС- 000103</td>
            <td>
              БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ ЧУВАШСКОЙ РЕСПУБЛИКИ
              &quot;МАРИИНСКО-ПОСАДСКИЙ ЦЕНТР СОЦИАЛЬНОГО ОБСЛУЖИВАНИЯ
              НАСЕЛЕНИЯ&quot; МИНИСТЕРСТВА ТРУДА И СОЦИАЛЬНОЙ ЗАЩИТЫ ЧУВАШСКОЙ
              РЕСПУБЛИКИ
            </td>
            <td>Природы верные друзья</td>
            <td>Чувашская республика - Чувашия, г Мариинский Посад</td>
          </tr>
          <tr>
            <td>12</td>
            <td>23-6-БС- 000385</td>
            <td>
              МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ ОБЩЕОБРАЗОВАТЕЛЬНОЕ УЧРЕЖДЕНИЕ
              &quot;ОСНОВНАЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ШКОЛА ИМЕНИ ТИМОФЕЯ ИВИНА С.
              ИННОКЕНТЬЕВКА&quot;
            </td>
            <td>Ацаси онгбори (в переводе с нанайского НЕЛЬЗЯ ЗАБЫВАТЬ)</td>
            <td>Хабаровский край, Нанайский р-н, село Иннокентьевка</td>
          </tr>
          <tr>
            <td>13</td>
            <td>23-6-БС- 000406</td>
            <td>
              МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ &quot;ИНФОРМАЦИОННО-РЕСУРСНЫЙ
              ЦЕНТР КУЛЬТУРЫ ШУМЕРЛИНСКОГО МУНИЦИПАЛЬНОГО ОКРУГА&quot;
            </td>
            <td>
              Экологический иммерсивный спектакль &quot;В соавторстве с
              природой&quot;
            </td>
            <td>Чувашская республика - Чувашия, г Шумерля</td>
          </tr>
          <tr>
            <td>14</td>
            <td>23-6-БС- 000150</td>
            <td>
              АВТОНОМНАЯ НЕКОММЕРЧЕСКАЯ ОРГАНИЗАЦИЯ &quot;ЦЕНТР СОЦИАЛЬНОЙ
              ПОДДЕРЖКИ ЛЮДЕЙ С ОГРАНИЧЕННЫМИ ВОЗМОЖНОСТЯМИ ЗДОРОВЬЯ
              &quot;РАВНЫЕ&quot;
            </td>
            <td>&quot;Наследие Орловщины&quot;</td>
            <td>Орловская обл, г Орёл</td>
          </tr>
          <tr>
            <td>15</td>
            <td>23-6-БС- 000228</td>
            <td>
              МУНИЦИПАЛЬНОЕ БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ КУЛЬТУРЫ ЦЕНТРАЛИЗОВАННАЯ
              БИБЛИОТЕЧНАЯ СИСТЕМА &quot;НАВАШИНСКАЯ&quot;
            </td>
            <td>V Восторговские чтения</td>
            <td>Нижегородская обл, г Навашино</td>
          </tr>
          <tr>
            <td>16</td>
            <td>23-6-БС-000043</td>
            <td>
              БЮДЖЕТНОЕ УЧРЕЖДЕНИЕ ЧУВАШСКОЙ РЕСПУБЛИКИ &quot;ЧУВАШСКАЯ
              РЕСПУБЛИКАНСКАЯ СПЕЦИАЛЬНАЯ БИБЛИОТЕКА ИМЕНИ Л.Н.ТОЛСТОГО&quot;
              МИНИСТЕРСТВА КУЛЬТУРЫ, ПО ДЕЛАМ НАЦИОНАЛЬНОСТЕЙ И АРХИВНОГО ДЕЛА
              ЧУВАШСКОЙ РЕСПУБЛИКИ
            </td>
            <td>Инклюзивная творческая студия «Дети Улыпа»</td>
            <td>Чувашская республика, г Чебоксары</td>
          </tr>
          <tr>
            <td>17</td>
            <td>23-6-БС- 000228</td>
            <td>
              БЕЛГОРОДСКАЯ РЕГИОНАЛЬНАЯ ОБЩЕСТВЕННАЯ ОРГАНИЗАЦИЯ &quot;СОЮЗ
              СОВЕТСКИХ ОФИЦЕРОВ&quot;
            </td>
            <td>Игра-путешествие &quot;Внуки Победителей&quot;</td>
            <td>Белгородская обл, г Белгород</td>
          </tr>
          <tr>
            <td>18</td>
            <td>23-6-БС- 000228</td>
            <td>
              МУНИЦИПАЛЬНОЕ АВТОНОМНОЕ УЧРЕЖДЕНИЕ ДОПОЛНИТЕЛЬНОГО ОБРАЗОВАНИЯ
              &quot;ЦЕНТР ДОПОЛНИТЕЛЬНОГО ОБРАЗОВАНИЯ&quot;
            </td>
            <td>Ремесленная мастерская &quot;Загляденье&quot;</td>
            <td>Свердловская обл, Каменский р-н, пгт Мартюш</td>
          </tr>
          <tr>
            <td>19</td>
            <td>23-6-БС- 000228</td>
            <td>
              АВТОНОМНАЯ НЕКОММЕРЧЕСКАЯ ОРГАНИЗАЦИЯ РАЗВИТИЯ КУЛЬТУРЫ И
              ИСКУССТВА &quot;ПРОРЫВ&quot;
            </td>
            <td>ЮРТА - юниоры роботизированного технологического агентства</td>
            <td>Ульяновская область, с.п. Большенагаткинское</td>
          </tr>
          <tr>
            <td>20</td>
            <td>23-6-БС- 000228</td>
            <td>
              ФОНД РАЗВИТИЯ ПРОИЗВОДСТВЕННЫХ СИСТЕМ И ПРОМЫШЛЕННОГО ТУРИЗМА
            </td>
            <td>Кузница эффективных кадров</td>
            <td>г. Москва</td>
          </tr>
          <tr>
            <td>21</td>
            <td>23-6-БС- 000228</td>
            <td>
              ФОНД ПОДДЕРЖКИ И РАЗВИТИЯ ЖЕНСКИХ ИНИЦИАТИВ &quot;ОБЪЕДИНЕНИЕ
              ЖЕНЩИН АТОМНОЙ ОТРАСЛИ&quot;
            </td>
            <td>
              Просветительский онлайн- марафон &quot;Женщины за продвижение
              принципов бережливого сознания&quot;
            </td>
            <td>г. Москва</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
