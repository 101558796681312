import { createSlice } from "@reduxjs/toolkit";

export const adsSliderSlice = createSlice({
    name: 'adsSlider',
    initialState:[
        { id: 0, text: `29 февраля в рамках проекта "Помним, познаем, храним и передаем другим"  состоится экскурсия на тему  И.С.Черных Герой Советского Союза-"Огненный летчик".
        Сбор в 13ч.45мин у главного корпуса Томского индустриального техникума, по адресу, ул.Беленца, д.11. В 14-00 начало экскурсии. Продолжительность экскурсии примерно 1,5 часа
        Убедительная просьба кто желает посетить экскурсию сообщите мне лично  до 28 февраля включительно, т.к. посадочные места в автобусе ограничены.` },
        { id: 1, text: `02 марта начало в 15-00 в Отделе  библиотечного обслуживания, пер.Батенькова, д.1 состоится Тематическая программа с конкурсом супружеских пар и концертной программой посвященная Дню 8 марта.` },
     
      ],
      reducers: {
        reducer: (state) => state,
      },
})

export const { reducer } = adsSliderSlice.actions;

export default adsSliderSlice.reducer;